import { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { rem } from 'polished';

export type ButtonDecoration = 'default' | 'underline';
export type ButtonKind = 'black' | 'primary' | 'gold' | 'secondary';
export type ButtonSize = 'default' | 'sm';

export const buttonKindConfig: Record<ButtonKind, ReturnType<typeof css>> = {
  ['black']: css`
    background: transparent;
    border: 0.125rem solid ${colors.grey1};
    color: ${colors.grey1};
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;

    &:hover {
      background: ${colors.grey1};
      color: ${colors.white};
      outline: none;
    }
  `,
  ['primary']: css`
    background: transparent;
    border: 0.125rem solid ${colors.white};
    color: ${colors.white};
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;

    &:hover {
      background: ${colors.white};
      color: ${colors.itpGold};
      outline: none;
    }
  `,
  ['gold']: css`
    background: transparent;
    border: 0.125rem solid ${colors.itpGold};
    color: ${colors.itpGold};
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;

    &:hover {
      background: ${colors.itpGold};
      color: ${colors.white};
      outline: none;
    }
  `,
  ['secondary']: css`
    background: ${colors.white};
    border: 0.125rem solid transparent;
    color: ${colors.itpGold};
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;

    &:hover {
      background: transparent;
      border-color: ${colors.white};
      color: ${colors.white};
      outline: none;
    }
  `,
} as const;

export const buttonKindLoadingConfig: Record<
  ButtonKind,
  ReturnType<typeof css>
> = {
  ['black']: css`
    &:disabled {
      background: ${colors.itpGold};
      opacity: 1;
    }
  `,
  ['primary']: css`
    &:disabled {
      background: ${colors.itpGold};
      opacity: 1;
    }
  `,
  ['gold']: css`
    &:disabled {
      background: ${colors.itpGold};
      opacity: 1;
    }
  `,
  ['secondary']: css`
    background: ${colors.itpGold};
    border: 0.125rem solid transparent;
    border-radius: 0.25rem;
    color: ${colors.white};
    font-weight: 600;
    line-height: 1rem;
    text-align: center;

    &:active:not(:disabled) {
      background: ${colors.itpGold};
      outline: none;
    }

    &:disabled {
      background: ${colors.itpGold};
      opacity: 1;
    }
  `,
} as const;

export const buttonSizeConfig: Record<ButtonSize, ReturnType<typeof css>> = {
  ['default']: css`
    font-size: 1rem;
  `,
  ['sm']: css`
    font-size: 0.75rem;
    border-width: 1px;
  `,
} as const;

export const buttonSpacingConfig: Record<ButtonSize, ReturnType<typeof css>> = {
  ['default']: css`
    height: 50px;
    padding: 0 15px;
  `,
  ['sm']: css`
    height: 1.625rem;
    padding: 0.375rem 0.625rem;
  `,
} as const;

export const buttonLoadingSizeConfig: Record<ButtonSize, number> = {
  ['default']: 22,
  ['sm']: 22,
} as const;

export const buttonSpacingLoadingConfig: Record<
  ButtonSize,
  ReturnType<typeof css>
> = {
  ['default']: css`
    padding-left: ${rem(23 + buttonLoadingSizeConfig['default'] / 2)};
    padding-right: ${rem(23 + buttonLoadingSizeConfig['default'] / 2)};
  `,
  ['sm']: css`
    padding-left: ${rem(23 + buttonLoadingSizeConfig['default'] / 2)};
    padding-right: ${rem(23 + buttonLoadingSizeConfig['default'] / 2)};
  `,
} as const;
