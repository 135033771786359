import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g transform="translate(79 50)">
      <circle r="6">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.875s"
          values="1.5 1.5;1 1"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
          values="1;0"
          begin="-0.875s"
        />
      </circle>
    </g>
    <g transform="rotate(45 -49.85534 120.36144)">
      <circle r="6" fillOpacity=".875">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.75s"
          values="1.5 1.5;1 1"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
          values="1;0"
          begin="-0.75s"
        />
      </circle>
    </g>
    <g transform="rotate(90 -14.5 64.5)">
      <circle r="6" fillOpacity=".75">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.625s"
          values="1.5 1.5;1 1"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
          values="1;0"
          begin="-0.625s"
        />
      </circle>
    </g>
    <g transform="rotate(135 .14466 41.36144)">
      <circle r="6" fillOpacity=".625">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.5s"
          values="1.5 1.5;1 1"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
          values="1;0"
          begin="-0.5s"
        />
      </circle>
    </g>
    <g transform="rotate(180 10.5 25)">
      <circle r="6" fillOpacity=".5">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.375s"
          values="1.5 1.5;1 1"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
          values="1;0"
          begin="-0.375s"
        />
      </circle>
    </g>
    <g transform="rotate(-135 20.85534 8.63856)">
      <circle r="6" fillOpacity=".375">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.25s"
          values="1.5 1.5;1 1"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
          values="1;0"
          begin="-0.25s"
        />
      </circle>
    </g>
    <g transform="rotate(-90 35.5 -14.5)">
      <circle r="6" fillOpacity=".25">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.125s"
          values="1.5 1.5;1 1"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
          values="1;0"
          begin="-0.125s"
        />
      </circle>
    </g>
    <g transform="rotate(-45 70.85534 -70.36144)">
      <circle r="6" fillOpacity=".125">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="0s"
          values="1.5 1.5;1 1"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
          values="1;0"
          begin="0s"
        />
      </circle>
    </g>
  </IconBox>
);
