import { media } from 'src/config/breakpoints';
import styled, { css } from 'styled-components';

export const MaxWidthContainer = styled.div`
  margin: 0 auto;

  ${media.w.min.px992(css`
    max-width: 56.25rem;
  `)}

  ${media.w.min.px1200(css`
    max-width: 75rem;
  `)}

  ${media.w.min.px1366(css`
    max-width: 85.375rem;
  `)}
`;

export const InnerContainer = styled.div`
  padding-left: 1.875rem;

  ${media.w.min.px768(css`
    padding-left: 2rem;
  `)}

  ${media.w.min.px1200(css`
    padding-left: 1.9375rem;
  `)}

  ${media.w.min.px1366(css`
    padding-left: 1.9375rem;
  `)}
`;
